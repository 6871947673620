import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

function Privacidad() {
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Política de Privacidad",
        }}
      />
      <main className="container">
        <section className="mt-10 w-10/12 space-y-5">
          <h6 className="text-lg text-exoticberry">
            AVISO DE PRIVACIDAD PARA EL SITIO WEB DE BOONE’S MEXICO
          </h6>
          <h6 className="text-lg text-exoticberry">
            SOBRE ESTE AVISO DE PRIVACIDAD
          </h6>
          <p>
            Somos la marca Boone’s, propiedad de E. & J. Gallo Winery, 600
            Yosemite Boulevard, Modesto, California, 95354 («Boone’s»,
            «nosotros», «nuestro» or «nuestros»). E. & J. Gallo Winery es el
            Controlador de Datos. Nosotros creamos este Aviso de Privacidad para
            informarle cómo recolectamos, utilizamos y revelamos su información
            identificable personal («información personal») que obtenemos de
            usted. Este Aviso de Privacidad no es aplicable, sin embargo, a
            nuestros empleados, solicitantes de empleo, proveedores,
            distribuidores o contactos comerciales salvo cuando actúen en su
            carácter de «cliente». Mientras que nuestro sitio web, u otros
            materiales podrán incluir enlaces o referencias a sitios web,
            páginas, materiales o entidades que no pertenecen a Boone’s, este
            Aviso de Privacidad no es aplicable a dichos sitios, páginas,
            materiales o entidades, a menos de que se indique lo contrario.
            Deberá leer este Aviso de Privacidad en su totalidad previo a
            enviarnos información o utilizar nuestro sitio web. Siempre que nos
            envíe información a través de nuestro sitio web o de cualquier
            forma, ya sea en línea o fuera de línea, usted consiente a la
            recolección, uso y revelación de esta información de conformidad
            este Aviso de Privacidad. Si usted no está de acuerdo con este Aviso
            de Privacidad en su totalidad, usted no deberá utilizar este sitio
            web.
          </p>
          <h6 className="text-lg text-exoticberry">
            PÚBLICO OBJETIVO DEL SITIO WEB
          </h6>
          <p>
            En virtud de la naturaleza de nuestro negocio, el público objetivo
            de nuestro sitio web son individuos mayores de edad, con edad legal
            para consumir bebidas alcohólicas. Como resultado de lo anterior,
            nuestro sitio web no solicita o recopila con conocimiento datos
            personales de personas menores de 18 años. Si usted no tiene 18 años
            o más de edad, usted no debería visitar nuestro sitio web.
          </p>
          <h6 className="text-lg text-exoticberry">
            RECOPILACIÓN Y USO DE INFORMACIÓN
          </h6>
          <p>
            Podemos recopilar datos personales de clientes tanto en línea como
            fuera de línea. Los datos personales que recopilamos de los usuarios
            incluyen: La información de contacto, incluyendo nombre, título,
            dirección de la calle (casa, trabajo, facturación, envío), código
            postal, país, número de teléfono (casa, trabajo, celular), dirección
            de correo electrónico (casa, trabajo) y fecha de nacimiento. La
            información de autenticación, incluyendo los ID de usuario para
            acceder al sistema nombres, contraseñas, recordatorios de
            contraseñas. la información transaccional incluye información acerca
            de los servicios prestados en el sitio web, y cómo interactúas con
            nosotros y el sitio web (por ejemplo a través de correo electrónico,
            página web, o por teléfono). Información de facturación incluye la
            información de facturación, tales como nombre y dirección, y un
            número de tarjeta de débito/crédito, incluyendo el número de
            seguridad de la tarjeta y la fecha de la tarjeta de crédito de la
            espiración. En línea, podemos recopilar información a través de
            nuestro sitio web, un sitio web afiliado (como un micrositio
            promocional vinculado a nuestro sitio), nuestra página en un sitio
            web de la comunidad o por vías similares en línea. En muchas
            ocasiones, dicha información será obtenida directamente de usted,
            tal como en una operación o solicitud (incluyendo vía telefónica) o
            la presentación de un formulario, registro o comunicación con
            nosotros. También podremos combinar esto con información sobre Usted
            que hayamos obtenido legalmente de otras fuentes tales como
            proveedores de listas de registros y nuestros afiliados. Conforme
            usted utilice nuestro sitio web, alguna información puede ser
            «pasivamente» recopilada utilizando diversas tecnologías como
            cookies, contadores de visitas y recopilación de datos de navegación
            (clickstream, archivos de registro, registros de servidor). Su
            navegador de Internet automáticamente transmite alguna de esta
            información, como por ejemplo la dirección URL del sitio web que
            usted acaba de visitar y la dirección del Protocolo de Internet (IP)
            y versión de navegador que actualmente utiliza su computadora.
            Sujeto a su consentimiento, dichas tecnologías nos apoyan para
            mantener un registro de su interacción con nuestro sitio web y/o
            Página y le otorga una experiencia más personalizada. Para mayor
            información sobre nuestro uso de cookies y tecnología similar y su
            derecho de consentir a este uso y subsecuentemente retirar tal
            consentimiento [seleccione aquí – enlace a la Política de Cookies].
            Utilizamos datos personales de los usuarios para un amplio rango de
            fines. Dependiendo de sus preferencias, podemos utilizarlos (i) para
            responder a sus solicitudes (incluyendo preguntas, comentarios,
            solicitudes de suscripciones a boletines, materiales,
            cupones/rebajas/descuentos, etc.); (ii) para invitarlo a participar
            en una gama de eventos en línea y fuera de línea, actividades y/o
            clubs; (iii) para mejorar nuestros productos, servicios y sitios
            web; (iv) para darle seguimiento con usted después de que usted se
            comunicó con nosotros o de que nos presentó información; (v) para
            enviar comunicaciones promocionales u ofertas; (vi) para mercadeo,
            investigaciones; (vi) fines legales; y (vii) grabaciones de centros
            de llamadas). Si usted así lo desea, podemos utilizarlo para
            permitirle participar en y de otra forma administrar promociones y
            concursos; y de cualquier otra forma especificada en este Aviso de
            Privacidad y/o en el punto de recopilación de datos personales. Los
            fines descritos en los numerales ii), iii) y v) a los que se hace
            referencia arriba no son necesarios para mantener la relación
            jurídica que tenemos con usted. Por lo tantro en cualquier momento
            puede usted manifestar su decisión de oponerse al tratamiento de sus
            datos para estos fines o revocar el consentimiento que fue otorgado
            anteriormente, mediante el envío de un correo electrónico al
            contacto@localhost . En algunos casos, trabajamos con empresas para
            recopilar información de usted o ayudarnos a comunicarnos con usted.
            Previo aviso y su consentimiento, podríamos revelar información
            personal que usted nos ha proporcionado y a estas empresas que han
            acordado mantener la información confidencial y utilizarla
            únicamente para prestar el(los) servicio(s) correspondiente(s). De
            conformidad con las preferencias que usted ha expresado, podemos
            revelar sus datos personales a nuestras afiliadas o marcas
            relacionadas, o a terceros que trabajan en nombre de estas afiliadas
            o marcas relacionadas, para permitirles comercializar sus productos
            y servicios con usted. Si un tercero adquiere todo (o
            substancialmente todo) nuestro negocio y/o activos, podremos
            divulgar su información personal a dicho tercero en relación con la
            adquisicón. También podremos divulgar su información personal cuando
            se requiere por la ley aplicable, por una agencia gubernamental o
            por cualquier autoridad competente. Los receptores de su información
            personal podrá localizarse en los Estados Unidos o en otras
            jurisdicciones que tal vez no provean el nivel de protección
            equivalente a las leyes en su jurisdicción. Al enviarnos datos
            personales, usted consiente en la transferencia de dichos datos
            personales fuera de su jurisdicción. Podemos hacer uso total de toda
            la información que no sea identificable o no tenga un formato para
            ser personalmente identificable.
          </p>
          <h6 className="text-lg text-exoticberry">SEGURIDAD</h6>
          <p>
            Tomamos las medidas apropiadas para proteger los datos personales de
            pérdidas, uso indebido y acceso no autorizado, revelación,
            alteración o destrucción, ya sea en la transmisión o almacenamiento.
            No obstante, favor de tomar en cuenta que no existe tal cosa como
            seguridad total, y ninguna transferencia realizada en Internet se
            puede considerar completamente segura o libre de errores.
          </p>
          <h6 className="text-lg text-exoticberry">
            ES SU DECISIÓN: PREFERENCIAS DE CONTACTO
          </h6>
          <p>
            Le ofrecemos opciones para limitar la forma en que usamos o
            divulgamos sus datos personales. Queremos seguir en contacto con
            usted en las formas que usted encuentre que le son provechosas. Por
            lo tanto, antes de contactarlo con fines de promoción, vamos a
            ofrecer un mecanismo que le permite expresar su negativa a recibir
            este tipo de comunicaciones. Usted también nos puede indicar su
            preferencia más adelante contactándonos según lo especificado en la
            sección siguiente de «Contáctenos», o siguiendo las instrucciones
            incluidas en varias de las comunicaciones que podamos enviarle.
            Favor de tomar en cuenta que estas preferencias con relación al
            contacto promocional no significa que no podamos contactarlo para
            otros fines, tales como aquellos relacionados con alguna orden que
            usted realice, algún boletín al cual usted se suscriba, alguna
            membresía que usted obtenga, algún evento al cual usted se registre,
            alguna notificación legal, y así sucesivamente.
          </p>
          <h6 className="text-lg text-exoticberry">
            SOLICITUDES Y CORRECCIONES DE USUARIO O PREGUNTAS RELACIONADAS CON
            LA INFORMACIÓN DEL USUARIO
          </h6>
          <p>
            Si su nombre, correo electrónico, dirección postal, número
            telefónico o cualquier otra información de contacto cambia, usted
            puede realizar dichos cambios en su información o cuenta. Si usted
            tiene alguna solicitud o pregunta en relación con dicha información,
            incluyendo el uso por nuestras afiliadas para fines de mercadeo,
            usted puede enviarnos un correo electrónico según lo indicado en la
            sección de «Contáctenos». Previa recepción de una solicitud de
            usted, tomaremos los pasos necesarios para actualizar o corregir su
            información. Usted tiene los derechos para acceder, corregir y
            solicitar la eliminación de sus datos personales de conformidad con
            la ley aplicable. Usted también tiene derecho a oponerse a ciertas
            prácticas de procesamiento de datos o a revocar su consentimiento
            previamente otorgado, en la medida permitida por la ley. En caso de
            que tenga cualquier pregunta o inquietud en relación con la forma en
            que manejamos sus datos personales o si desea ejercer sus derechos
            mencionados anteriormente, o realizar cualquier otro tipo de
            solicitud, favor de contactarnos de conformidad con la sección
            «Contáctenos». Le informaremos sobre: (i) la información que se le
            solicitará (en su caso) a usted o a su representante legal y la
            documentación que pueda requerir adjuntar a su solicitud; (ii)
            tiempo para recibir una respuesta de nuestra parte en relación con
            cualquier solicitud; (iii) formas y plantillas disponibles para
            presentar su solicitud (en su caso); y (iv) cómo le proporcionaremos
            a usted la información (que normalmente consistirá en copias de
            documentos o mensajes de datos). Nos reservamos el derecho de
            conservar cualquier información razonablemente necesaria para
            documentar de manera adecuada nuestras actividades de negocio y para
            fines de archivo y mantenimiento de registros necesarios para los
            fines descritos en este Aviso de Privacidad.
          </p>
          <h6 className="text-lg text-exoticberry">CONTÁCTENOS</h6>
          <p>
            Si tiene preguntas o inquietudes relacionadas con este Aviso de
            Privacidad o nuestras prácticas de información, quisiera actualizar
            o corregir información que ha proporcionado o quisiera
            desinscribirse de nuestras comunicaciones promocionales, favor de
            contactarnos en: Atención: Relaciones con el Consumidor
            contacto@localhost.
          </p>
          <h6 className="text-lg text-exoticberry">
            CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD
          </h6>
          <p>
            Si este Aviso de Privacidad sufre modificaciones, la política
            modificada tendrá una nueva fecha de entrada en vigor y se publicará
            en esta página. Asegúrese de verificar regularmente para mantenerse
            actualizado sobre este Aviso de Privacidad. La fecha de entrada en
            vigor de este Aviso de Privacidad es Enero 2016.
          </p>
        </section>
      </main>
    </Layout>
  );
}

export default Privacidad;
